import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { ArrowDown, Clock } from 'react-feather'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  category?: string
  image?: ImageProps
  languageCode: string
  priceValue?: string
  priceLabel?: string
  title?: string
  validity?: string
}

export const OfferHero = memo(function OfferHero({
  category,
  image,
  languageCode,
  priceValue,
  priceLabel,
  title,
  validity,
}: Props) {
  const onClick = () => {
    window.scroll({
      top: window.innerHeight + 1,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Container>
      {image ? <Background effect="blur" {...image} /> : null}
      <Info dial={4} row wrap>
        <LeftSide>
          {category ? <Category>{category}</Category> : null}
          {title ? <Title>{title}</Title> : null}
          {validity ? (
            <Validity dial={4} row>
              <Clock />
              {validity}
            </Validity>
          ) : null}
        </LeftSide>
        <RightSide dial={5} row space="between">
          {priceValue ? (
            <PriceFrom>
              <PriceTitle>
                {useVocabularyData('starting-from', languageCode)}
              </PriceTitle>
              <PriceValue>{priceValue}</PriceValue>
              <PriceLabel>{priceLabel}</PriceLabel>
            </PriceFrom>
          ) : null}
          <ButtonScroll dial={5} onClick={onClick}>
            <ArrowDown />
          </ButtonScroll>
        </RightSide>
      </Info>
    </Container>
  )
})

const Container = styled.section`
  height: 100vh;
  max-height: -webkit-fill-available;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:before {
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.42)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
  }
  &:after {
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
  }
`

const Background = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Info = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 2.75rem 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
`

const LeftSide = styled.div`
  width: 64%;
  padding-right: 8.472vw;
  padding-left: 10vw;

  @media (max-width: 1199px) {
    padding-right: 5vw;
    padding-left: 8.454vw;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 8.454vw;
  }
`

const Category = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.75rem;
  letter-spacing: 0.1125rem;
  line-height: 0.875rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.0563rem;
  line-height: 2.25rem;
  text-transform: uppercase;
`

const Validity = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin-top: 1.25rem;
  svg {
    width: auto;
    height: 1.25rem;
    margin-right: 0.625rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
    stroke-width: 1.5;
  }
`

const RightSide = styled(FlexBox)`
  width: 36%;
  padding-right: 10vw;

  @media (max-width: 1199px) {
    padding-right: 8.454vw;
  }

  @media (max-width: 1023px) {
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: flex-start;
    margin-top: 2.5rem;
    padding: 0 8.454vw;
  }
`

const PriceFrom = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
`

const PriceTitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 0.75rem;
  letter-spacing: 0.0375rem;
  line-height: 0.875rem;
  text-transform: uppercase;
`

const PriceValue = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
`

const PriceLabel = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-size: 0.9375rem;
  line-height: 1.125rem;
  margin-left: 0.4375rem;
`

const ButtonScroll = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 2px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  z-index: 3;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.3)};
    border-color: transparent;
  }
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`
