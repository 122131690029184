import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Benefits } from './Benefits'
import { Package, Props as PackageProps } from './Package'
import { Paragraph, Props as ParagraphProps } from './Paragraph'

export interface Props {
  availabilityURL?: string
  benefits?: string[]
  benefitsTitle?: string
  contactaURL?: string
  languageCode: string
  packagesTitle?: string
  packages?: PackageProps[]
  paragraphs?: ParagraphProps[]
}

export const OfferContent = memo(function OfferContent({
  availabilityURL,
  benefits,
  benefitsTitle,
  contactaURL,
  languageCode,
  packagesTitle,
  packages,
  paragraphs,
}: Props) {
  return (
    <Container row tag="section" wrap>
      <LeftSide>
        {paragraphs
          ? paragraphs.map((item, index) => <Paragraph key={index} {...item} />)
          : null}
        {packages ? (
          <Wrapper>
            {packagesTitle ? (
              <Fade bottom distance="3.75rem">
                <PackagesTitle>{packagesTitle}</PackagesTitle>
              </Fade>
            ) : null}
            {packages.map((item, index) => (
              <Fade key={index} bottom distance="3.75rem">
                <Package {...item} />
              </Fade>
            ))}
          </Wrapper>
        ) : null}
        <style type="text/css">{mediaStyle}</style>
        <MediaContextProvider>
          <Media lessThan="ipadHorizontal">
            {availabilityURL ? (
              <AvailabilityCTA
                label={useVocabularyData('check-availability', languageCode)}
                URL={availabilityURL}
              />
            ) : null}
            {contactaURL ? (
              <ContactsCTA
                label={useVocabularyData('request-info', languageCode)}
                URL={contactaURL}
              />
            ) : null}
          </Media>
        </MediaContextProvider>
      </LeftSide>
      <RightSide>
        <Benefits
          availabilityURL={availabilityURL}
          benefits={benefits}
          contactaURL={contactaURL}
          languageCode={languageCode}
          title={benefitsTitle}
        />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 12rem;

  @media (max-width: 1199px) {
    padding-bottom: 7.5rem;
  }

  @media (max-width: 1023px) {
    padding-bottom: 5.625rem;
  }

  @media (max-width: 767px) {
    padding-bottom: 3.75rem;
  }
`

const LeftSide = styled.div`
  width: 64%;
  padding-right: 8.472vw;
  padding-left: 10vw;

  @media (max-width: 1199px) {
    padding-right: 6vw;
    padding-left: 8.454vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 3.75rem;
    padding-right: 8.454vw;
  }
`

const AvailabilityCTA = styled(Button)`
  display: block;
  margin-top: 3.75rem;
  text-align: center;
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
  }
`

const ContactsCTA = styled(Button)`
  display: block;
  background: none;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  margin-top: 0.75rem;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.colors.variants.neutralDark1};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    margin-top: 0.75rem;
  }
`

const RightSide = styled.div`
  width: 36%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 3.75rem 8.472vw 3.75rem 3.75rem;

  @media (max-width: 1199px) {
    padding-right: 8.454vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-left: 8.454vw;
  }

  @media (max-width: 767px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

const Wrapper = styled.div`
  margin-top: 3.75rem;
`

const PackagesTitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
`
