import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Offer, Props as OfferProps } from 'app/components/OffersList/Offer'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

export interface Props {
  languageCode: string
  offers: OfferProps[]
}

function PrevArrow(props: any) {
  return (
    <Arrow className="slider-prev" dial={5} onClick={props.onClick}>
      <ArrowLeft />
    </Arrow>
  )
}

function NextArrow(props: any) {
  return (
    <Arrow className="slider-next" dial={5} onClick={props.onClick}>
      <ArrowRight />
    </Arrow>
  )
}

export const OtherOffers = memo(function OtherOffers({
  languageCode,
  offers,
}: Props) {
  const settings = {
    draggable: false,
    infinite: offers.length > 3 ? true : false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: offers.length > 2 ? 3 : offers.length,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: false,
          draggable: true,
          slidesToShow: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          autoplay: false,
          draggable: true,
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  }

  if (offers.length < 1) {
    return null
  }

  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <Title>{useVocabularyData('other-offers-title', languageCode)}</Title>
      </Fade>
      <Fade bottom distance="3.75rem">
        <Slider lazyLoad="progressive" {...settings}>
          {offers.map((item, index) => (
            <Offer key={index} {...item} />
          ))}
        </Slider>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 0 10vw 7.6875rem;

  .slick-slider {
    margin-top: 3.75rem;
  }
  .slick-list {
    margin: 0 -1.0625rem;
  }
  .slick-track {
    display: flex;
    margin-left: 0;
  }
  .slick-slide {
    width: 33.333%;
    margin: 0 1.0625rem;
    a {
      display: block;
    }
  }

  @media (max-width: 1199px) {
    padding: 0 8.454vw 5.625rem;
  }

  @media (max-width: 1023px) {
    padding: 0 0 3.75rem;

    .slick-slider {
      margin-top: 1.875rem;
    }
    .slick-list {
      padding: 0 3rem;
      overflow: visible;
    }
    .slick-slide {
      width: calc(50vw - 2.25rem);
      margin: 0 0.375rem;
    }
  }

  @media (max-width: 767px) {
    .slick-slide {
      width: calc(100vw - 4.25rem);
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.375rem;
  text-align: center;

  @media (max-width: 1023px) {
    text-align: center;
    padding: 0 8.454vw;
  }
`

const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 2.625rem);
  transition: 0.3s ease-in-out;
  transform: translateY(-50%);
  z-index: 2;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
    border-color: transparent;
  }
  &.slider-prev {
    left: -2.625rem;
  }
  &.slider-next {
    right: -2.625rem;
  }
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`
