import styled from '@emotion/styled'
import { OfferContent } from 'app/components/OfferContent'
import { OfferHero } from 'app/components/OfferHero'
import { OtherOffers } from 'app/components/OtherOffers'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Props as SidebarProps, Sidebar } from 'app/containers/Sidebar'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  sidebarProps?: SidebarProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function OfferPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.sidebarProps ? (
        <Sidebar
          languageCode={context.languageCode}
          {...context.sidebarProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.offerHeroProps ? (
        <OfferHero {...context.offerHeroProps} />
      ) : null}
      {context.offerContentProps ? (
        <OfferContent {...context.offerContentProps} />
      ) : null}
      {context.otherOffersProps ? (
        <OtherOffers {...context.otherOffersProps} />
      ) : null}
      {context.footerProps ? (
        <Footer
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          variant="compact"
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
