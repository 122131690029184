import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { Check } from 'react-feather'
import Fade from 'react-reveal/Fade'

export interface Props {
  availabilityURL?: string
  benefits?: string[]
  contactaURL?: string
  languageCode: string
  title?: string
}

export const Benefits = memo(function Benefits({
  availabilityURL,
  benefits,
  contactaURL,
  languageCode,
  title,
}: Props) {
  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {title ? (
          <Fade bottom distance="3.75rem">
            <Title>{title}</Title>
          </Fade>
        ) : null}
        {benefits
          ? benefits.map((item, index) => (
              <Fade key={index} bottom distance="3.75rem">
                <Benefit dial={4} key={index} row>
                  <SVG dial={5}>
                    <Check />
                  </SVG>
                  <Label>
                    {
                      // @ts-ignore
                      item.label
                    }
                  </Label>
                </Benefit>
              </Fade>
            ))
          : null}
        <Media greaterThanOrEqual="ipadHorizontal">
          {availabilityURL ? (
            <Fade bottom distance="3.75rem">
              <AvailabilityCTA
                label={useVocabularyData('check-availability', languageCode)}
                URL={availabilityURL}
              />
            </Fade>
          ) : null}
          {contactaURL ? (
            <Fade bottom distance="3.75rem">
              <ContactsCTA
                label={useVocabularyData('request-info', languageCode)}
                URL={contactaURL}
              />
            </Fade>
          ) : null}
        </Media>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-bottom: 1.875rem;
`

const Benefit = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  letter-spacing: 0.3px;
  line-height: 1.25rem;
  margin-top: 1rem;
`

const SVG = styled(FlexBox)`
  width: 2.25rem;
  height: 2.25rem;
  background: ${rgba(theme.colors.variants.neutralLight4, 0.16)};
  border-radius: 50%;
  margin-right: 0.75rem;
  svg {
    width: auto;
    height: 1.25rem;
  }
`

const Label = styled.label`
  width: calc(100% - 3rem);
`

const AvailabilityCTA = styled(Button)`
  margin-top: 2.5rem;
  text-align: center;
  &:after {
    background: ${theme.colors.variants.neutralDark2};
  }
`

const ContactsCTA = styled(Button)`
  background: none;
  margin-top: 0.75rem;
  text-align: center;
  &:hover {
    &:before {
      opacity: 0;
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }
  &:after {
    background: ${theme.colors.variants.neutralDark2};
  }
`
