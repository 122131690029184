import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { Clock } from 'react-feather'

export interface Props {
  buttonURL?: string
  description?: string
  image?: string
  languageCode: string
  price?: string
  subtitle?: string
  title?: string
  validity?: string
}

export const Package = memo(function Package({
  buttonURL,
  description,
  image,
  languageCode,
  price,
  subtitle,
  title,
  validity,
}: Props) {
  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Head row wrap>
          {image ? (
            <Image style={{ backgroundImage: `url(${image})` }} />
          ) : null}
          <Wrapper className={!image ? 'full-width' : undefined} dial={4}>
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
          </Wrapper>
        </Head>
        <Media greaterThanOrEqual="ipadHorizontal">
          {price ? <Price>{price}</Price> : null}
        </Media>
        <Info dial={4} row space="between" wrap>
          {validity ? (
            <Validity dial={5} row>
              <Clock />
              <Label>{validity}</Label>
            </Validity>
          ) : null}
          <Media lessThan="ipadHorizontal">
            {price ? <Price>{price}</Price> : null}
          </Media>
          {buttonURL ? (
            <CTA
              label={useVocabularyData('check-availability', languageCode)}
              URL={buttonURL}
            />
          ) : null}
        </Info>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 1.5rem;

  @media (max-width: 767px) {
    margin-top: 0.75rem;
  }
`

const Head = styled(FlexBox)``

const Image = styled.div`
  width: 10.75rem;
  height: 10.75rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 767px) {
    width: 100%;
    height: 9.375rem;
  }
`

const Wrapper = styled(FlexBox)`
  width: calc(100% - 12.25rem);
  padding: 1.5rem 2.25rem;
  &.full-width {
    width: 100%;
  }

  @media (max-width: 1199px) {
    padding: 1.25rem 1.875rem;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Title = styled.h4`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.375rem;
  margin-bottom: 0.5rem;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
`

const Price = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.375rem;
  margin-top: 0.375rem;
  padding: 0 2.25rem;
  text-align: right;

  @media (max-width: 1199px) {
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin-top: 1.875rem;
    padding: 0;
    text-align: left;
  }
`

const Info = styled(FlexBox)`
  margin-top: 0.75rem;
  padding: 0 2.25rem 2.25rem;

  @media (max-width: 1199px) {
    display: block;
    padding: 0 1.875rem 1.875rem;
  }
`

const Validity = styled(FlexBox)`
  flex: 1;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding-right: 2.25rem;
  svg {
    width: auto;
    height: 1.25rem;
    margin-right: 0.625rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark1};
    stroke-width: 1.5;
  }

  @media (max-width: 1199px) {
    justify-content: flex-start;
  }
`

const Label = styled.label`
  width: calc(100% - 1.875rem);
`

const CTA = styled(Button)`
  margin-top: 0;
  white-space: nowrap;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`
